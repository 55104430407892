import React from 'react'

const Heading = ({children, size, className}) => {

    switch(size) {

        case 'display':
            return <h2 className={`font-avant-garde text-7xl font-light lg:text-8xl leading-none ${className ? className : ""} mb-4`}>{children}</h2>
        case 'h1':
            return <h1 className={`font-avant-garde text-7xl lg:text-8xl leading-none font-light mb-4 ${className ? className : ""}`}>{children}</h1>
        case 'h2':
            return <h2 className={`font-poppins text-5xl lg:text-7xl leading-none font-light mb-4 ${className ? className : ""}`}>{children}</h2>
        case 'h3':
            return <h3 className={`font-poppins text-4xl lg:text-5xl leading-none font-normal mb-4 ${className ? className : ""}`}>{children}</h3>
        case 'h4':
            return <h4 className={`font-poppins text-3xl lg:text-4xl leading-none font-normal mb-4 ${className ? className : ""}`}>{children}</h4>
        case 'h5':
            return <h5 className={`font-poppins text-2xl leading-none font-normal mb-4 ${className ? className : ""}`}>{children}</h5>
        case 'h6':
            return <h6 className={`font-poppins text-base leading-none font-normal mb-4 ${className ? className : ""}`}>{children}</h6>
        default:
            return null

    }

}

export default Heading
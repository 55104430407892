import React from "react";
import { Link } from "gatsby";
import resolveLink from "src/utils/resolveLink";

const Button = ({ children, className, target, type, style, blank, onClick, size, flipped = false }) => {
  return (
    <Link
      className={`inline-flex items-center cursor-pointer transition ease-in-out duration-300 text-cyan hover:text-lagoon hover:translate-x-1 !no-underline ${size === 'large' ? 'text-lg lg:text-xl' : 'text-base lg:text-lg'} ${
        className ? className : ""
      }`}
      to={blank ? target : resolveLink(target)}
      type={type}
      style={style}
      target={blank ? '_blank' : ''}
      onClick={onClick ? onClick : ''}
    >
      <span>{children}</span>
      <i className={`fas fa-chevron-right ${size === 'large' ? 'text-sm relative top-[2px]' : 'text-xs'} ${size === 'very-large' && 'relative top-[3px] !text-base' } ${flipped ? "order-first rotate-180 mr-3": "order-last ml-3"}`}></i>
    </Link>
  );
};

export default Button;
import React, { useState, useEffect } from "react";
import Layout from "src/components/Layout"
import { PageContext } from "src/utils/context";
import Button from "src/components/Button"
import Label from "src/components/Label";
import Heading from "src/components/Heading";
import Star from "/src/images/star.svg";

const NotFoundPage = () => {
  
  const [state, setState] = useState(false)

    useEffect(() => {
        setState(true)
    }, [state])

  const location = { pathname: "/404/" };

  return (
      <PageContext.Provider
        value={{
          location: location
        }}
      >    
      <Layout location={{ pathname: "/404/" }}>
            <div className={`relative py-8 lg:py-14 bg-lagoon overflow-x-hidden`}>
                <div className="relative max-w-7xl mx-auto px-6 flex flex-col justify-center items-start h-full">
                    <Label>Page not found</Label>
                    <Heading size="display" className={`text-white max-w-4xl mb-8 ${ state ? "opacity-100 translate-x-0" : "opacity-0 translate-x-3"} transform ease transition-all duration-1000`}>
                        <span className={`mr-5 text-coral ${ state ? 'opacity-100' : 'opacity-0' } transition duration-[.75s]`}>
                          404
                        </span>
                        page not found
                    </Heading>
                    <div className="max-w-lg text-white text-base lg:text-lg mb-6">
                        Sorry the page you have requested was not found.
                    </div>
                    <Button className="max-w-md" target='/'>
                        Back to homepage
                    </Button>      
                </div>  
                <div class="absolute bottom-16 -right-7 rotate-[-70deg]">
                    <Star/>
                </div>        
            </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default NotFoundPage